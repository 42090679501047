import * as React from "react"
import Icon1 from "../assets/img/icons/goal.svg"
import Icon2 from "../assets/img/icons/credit-control.svg"
import Icon3 from "../assets/img/icons/web-advertising.svg"
import Icon4 from "../assets/img/icons/crowd.svg"
import Icon5 from "../assets/img/icons/megaphone.svg"
import Icon6 from "../assets/img/icons/transaction.svg"
import Introduction from "../components/intro"
import GetInTouch from "../components/getInTouch"
import Artwork from "../assets/img/artwork/artwork-12.svg"
import SubNav from "../components/subNav"
import Process from "../components/process"
import processJson from "../json/processes/social-media-marketing"

export default function Page() {
    return (
        <div className="service-page">
            <Introduction Artwork={Artwork}>
                <h1><span>Social</span> Media Marketing.</h1>
                <p className="text-larger"><strong>Grown your business, connect with your followers and advertise across networks.</strong></p>
            </Introduction>

            <SubNav
                title="Social Media Marketing"
                nav={['Features', 'Process', 'Get in Touch']}
            />
        

            <div className="container">
                <h3>Introduction</h3>
                <p>Many people are now using Facebook as a search engine. Instagram is a Facebook product, and you can <strong>target both platforms with one advert</strong>. Social Media Marketing will <strong>boost your brand awareness</strong>, and quickly convert a follower, into a customer.</p>
                <p>In addition to creating brand awareness, increasing website traffic and creating conversions, social media is a great way to interact with your client base. Find out what they are looking for by <strong>creating polls, communicate your brand's ideals and promote events or sales</strong>.</p>
                <h3>How It Works</h3>
                <p><strong>Paid Social Media Advertising</strong> involves the <strong>creation and running of ads</strong> on Facebook and Instagram as well as optimising your business page.</p>
                <p>The difference from the PPC ads on a search engine is that with social media you pay for the ads that are clicked on as well as for the ad being shown in the newsfeed or on the side bar (otherwise known as impressions).</p>
                <p>Ads are also shown to people who have previously visited your page, are searching for your particular services or product, or fall into your specific audience category.</p>
            </div>

            {/* Features */}
            <div id="features" className="component">
                <div className="container">
                    <h3 className="text-center font-normal cl-white">Features</h3>

                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
                        <div className="mt-8">
                            <Icon1 />
                            <h3 className="cl-primary mt-5">Targeted</h3>
                            <p>We can customise your ads according to your specified locations, time of day to show ads and where your ads are place across social networks.</p>
                        </div>
                        <div className="mt-8">
                            <Icon2 />
                            <h3 className="cl-primary mt-5">Budget Control</h3>
                            <p>Set a daily, weekly or monthly budget for your ad spend, we will monitor and adjust campaigns to ensure your budget is not exceeded.</p>
                        </div>
                        <div className="mt-8">
                            <Icon3 />
                            <h3 className="cl-primary mt-5">Customer Focused</h3>
                            <p>Your ads will reach the right people at the right time. Turning visitors into customers.</p>
                        </div>
                        <div className="mt-8">
                            <Icon4 className="icon-white" />
                            <h3 className="cl-primary mt-5">Unique Audience Targeting</h3>
                            <p>Want to only advertise to a certain demographic? We can target searchers based on their age, gender, interests and habits.</p>
                        </div>
                        <div className="mt-8">
                            <Icon5 />
                            <h3 className="cl-primary mt-5">Cross Platform</h3>
                            <p>Advertise on Facebook and Instgram from one ad account.</p>
                        </div>
                        <div className="mt-8">
                            <Icon6 />
                            <h3 className="cl-primary mt-5">Flexibility</h3>
                            <p>Need to pause a campaign? Make changes to ad copy? These are easily actionable.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container component">
                
                <p>Facebook's unique targeting capabilities are far more precise than Google Ads. You are able to target someone by their <strong>interests, relationship status, connections, education and even where they work.</strong></p>
                <p>For example: If you are recently engaged, you will be seeing a lot of advertising for wedding dresses, caterers and venues, but once you have updated your status to married, these ads will no longer be relevant to you and will not be shown.</p>
                <p>This ensures that your budget is not spend showing ads to people who do not fall within your target audience or location.</p>
                <h3>Our Social Media Marketing Services</h3>
                <p>The Social Media marketing we offer includes setup of business pages, paid advertising, monitoring and reporting on ads, Facebook and Instagram analytics, as well as page audits.</p>
                <p>To obtain a high ad ranking and ensure ad clicks and impressions are relevant, we do the following:</p>
                

            </div>

            {/* Our Process */}
            <div id="process" className="component double-padding-top vh-100 pb-32">
                <h2 className="text-center mb-0">Our Process<span className="symbol symbol-green"><span></span></span></h2>
                <Process data={processJson} />
                <div className="top-gradient-darker"></div>
            </div>

            <div className="container">
                <p><strong>Page Audits</strong></p>
                <p>We also offer a Social Media Page audit, where we will give our recommendations on how to best achieve your business goals through social media.</p>
                <p>In the age of Social Media, many people now prefer to make contact with businesses via their Facebook or Instagram page. By keeping your page up to date and posting fresh content, this shows that your business is active and <strong>lends credibility to your brand</strong>. We can even generate automated responses to frequently asked questions if you are unable to respond to messages at that time.</p>
                <h2>Pricing Structure</h2>
                <p>We cater to all different kinds of websites and budgets. Our fees are structured on an hourly rate. The amount of hours billed will depend on how many campaigns and ads are being managed.</p>
                <p>The first few months will require more hours due to the setup of ad accounts (where necessary), research needed to find the best possible keywords for your brand and audience, as well as making any necessary changes.</p>
                <p>Facebook requires payment for ad spend paid directly to them. We will set up your payment details when creating your advertising accounts.</p>
            </div>

                <GetInTouch />
            </div>
    )
}
